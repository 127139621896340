body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  /* Entferne folgende Zeilen:
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  */
}

.App {
  text-align: center;
}

.container {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 90%;
  margin: auto;
  padding: 20px;
}

button {
  margin-top: 10px;
}
